var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"doc-wrapper"},[_c('div',{staticClass:"print-button-wrapper"},[_c('v-btn',{staticClass:"no-print",attrs:{"color":"primary"},on:{"click":_vm.showPrintWindow}},[_vm._v(" Print / Save as PDF ")])],1),_c('div',{staticClass:"doc-letter-size"},[_c('div',{staticClass:"no-print",staticStyle:{"border":"dashed orangered 2px","padding":"4px"}},[_vm._v(" Page Size: Letter (8.5\" x 11\") ")]),_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v("Student Profile")]),(_vm.information.length > 0)?_c('div',[_c('v-row',[_c('v-col',[_c('h4',[_vm._v("Basic Info")])])],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.headers),function(header){return [(header.value in _vm.information[0])?_c('tr',{key:header.value},[_c('td',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(header.text))]),_c('td',[_vm._v(_vm._s(_vm.information[0][header.value]))])]):_vm._e()]})],2)]},proxy:true}],null,false,740634076)})],1)],1)],1):_vm._e(),_vm._l((_vm.steps),function(sectionTitle,sectionId){return _c('div',{key:sectionId},[_c('v-row',[_c('v-col',[_c('h4',[_vm._v(_vm._s(sectionTitle))])])],1),_c('div',{staticClass:"app-section-wrapper"},[_vm._l((_vm.schemaList[sectionId]),function(schemaItem,schemaItemId){return [(schemaItem.type !== 'submit')?_c('v-row',{key:schemaItemId,staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 0.12)","break-inside":"avoid-page"}},[_c('v-col',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"item-title"},[_c('div',{staticClass:"item-title-id"},[_vm._v(_vm._s(schemaItemId + 1))]),_c('div',[_vm._v(_vm._s(schemaItem.label))])]),_c('div',{staticClass:"item-content"},[(
                        typeof _vm.values[schemaItem.name] === 'string' &&
                          _vm.values[schemaItem.name] !== ''
                      )?_c('div',[(_vm.isUrl(_vm.values[schemaItem.name]))?_c('span',[_c('a',{attrs:{"href":_vm.values[schemaItem.name],"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.values[schemaItem.name])+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.values[schemaItem.name])+" ")])]):(
                        typeof _vm.values[schemaItem.name] === 'object' &&
                          _vm.values[schemaItem.name].length > 0
                      )?_c('div',[_vm._l((_vm.values[schemaItem.name]),function(item){return [(typeof item === 'string')?_c('v-chip',{key:item,staticStyle:{"margin":"4px","max-width":"90%","white-space":"normal","height":"auto"},attrs:{"color":"indigo darken-3","outlined":""}},[_vm._v(" "+_vm._s(item)+" ")]):(typeof item === 'object' && item.url)?_c('div',{key:JSON.stringify(item)},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name ? item.name : item.url)+" ")])]):_vm._e()]})],2):_c('div',{staticStyle:{"color":"grey","font-style":"italic"}},[_vm._v(" N/A ")])])])],1)],1)],1):_vm._e()]})],2)],1)}),(this.schema.length === 0)?_c('h2',{staticStyle:{"margin":"10px"}},[_vm._v(" Loading data... ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }